.notifications-page-container {
  margin-top: 110px;
  padding-inline: 90px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .notifications-page-container {
    padding-inline: 20px !important;
  }
}

.notifications-page-container .notificactions-page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.notifications-page-container .notificactions-page-header .page-header-left {
}

.notifications-page-container .notificactions-page-header .page-header-right {
  display: flex;
  gap: 20px;
}
.notifications-page-container
  .notificactions-page-header
  .page-header-right
  button {
  height: 40px;
  padding-inline: 12px;
  background-color: #dfdfdf;
  border-radius: 10px;
  width: 150px;
  font-size: 14px;
}

.notifications-page-container
  .notificactions-page-header
  .page-header-right
  .read-all {
  background-color: #002fa71a;
  color: #002fa7;
  font-weight: 700;
}

.notifications-page-container
  .notificactions-page-header
  .page-header-right
  .delete-all {
  background-color: #eb575715;
  color: #eb5757;
  font-weight: 700;
}

.notificactions-page-header p span {
  color: red;
  font-weight: 600;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.all-notifications-container-on-page {
  margin-block: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  padding-right: 4px;
  width: 100%;
}

.all-notifications-container-on-page .current-day-name {
  margin-top: 20px;
}

.all-notifications-container-on-page .container-single {
  padding-right: 20px;
}

.single-day-group-notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #002fa7;
  width: 100%;
  border-radius: 14px;
  background-color: #002fa71a;
  padding-block: 10px;
}

.notification-item-on-page {
  /* background-color: #002fa71a; */
  padding: 5px 10px;
  border-radius: 14px;
  cursor: pointer;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
}

.single-notification-message {
  font-style: italic;
  font-size: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 600;
}

.all-notifications-container-on-page .container-single.unread-item {
  background-color: rgb(189 219 243);
}

@media screen and (max-width: 768px) {
  .notifications-page-container
    .notificactions-page-header
    .page-header-right
    button {
    width: auto;
    font-size: 12px;
  }
}
