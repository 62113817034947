@import "../../style/variables";
@import "../../style/mixins";

.video-report-modal-container {
  padding: 20px;
  h2 {
    font-size: 23px;
    color: $primaryColor;
  }
  h6 {
    margin-top: 30px;
  }
  p {
    margin-top: 10px;
  }
  .report-modal-form-container {
    width: 100%;
    @include flex(column, flex-start, center, 20px);
    margin-top: 20px;
    textarea {
      height: 100px;
      width: 100%;
      border: 1px solid $primaryBorderColor;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
    }
    button {
      font-weight: 500;
      font-size: 16px;
      height: 40px;
      border-radius: 10px;
      padding-inline: 20px;
      color: #fff;
      background-color: $primaryColor;
    }
    .buttons-container {
      width: 100%;
      @include flex(row, center, center, 20px);
      .red-button {
        background-color: transparent;
        color: $errorColor;
      }
    }
  }
}

.ant-picker-footer {
  .ant-btn-primary {
    width: auto;
    height: auto;
  }
}
