.request-button{
    width: 130px;
    height: 30px;
    background: #002FA7;
    font-weight: 600;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    cursor: pointer;
  }
  .payment_Modal{
    padding: 40px;
  }