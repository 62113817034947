.why {
  margin-top: 30px;
}
.why-content p {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0d0d0d;
  padding-left: 10px;
  line-height: 20px;
  letter-spacing: 0px;
}
.span-color {
  color: #eb5757 !important;
  font-family: "Dancing Script" !important ;
}
@media screen and (max-width: 600px) {
  .span-color {
    color: #eb5757;
    font-family: "Dancing Script";
  }
}
/* @media screen and (max-width: 767px) {
  .works-headings{
    padding-top: 0rem;
  }
} */

@media (min-width: 768px) and (max-width: 992px) {
  .why-american-clinic .col-lg-4 {
    width: 50% !important;
  }
}
