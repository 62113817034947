@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y:hidden; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  /* font-family: "Adamina", serif;
  font-family: "Dancing Script", cursive;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif; */
  letter-spacing: -0.4px;
  color: #333333;
  margin: 0;
  letter-spacing: 0.3px !important;
  margin-bottom: 0px !important;
  /* background-position: right;
  background-repeat: no-repeat;
  background-image: url(../public/images/background-main.png);
  width: 100%;
  height: auto; */
}
p {
  margin-bottom: 0px !important;
}
a {
  text-decoration: none !important;
  /* color: #002fa7 !important; */
}

/* scroll */
/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(179, 179, 179);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgb(5, 181, 235); */
  border-radius: 10px;

  background: repeating-radial-gradient(
    circle,
    #0030a6 3px,
    #0030a6 3px,
    #0030a6 6px,
    #0030a6 6px
  );
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0030a6;
}

html {
  scroll-behavior: smooth;
}

::selection {
  color: white;
  background: black;
}
/* Header margin */
.prescription {
  margin-top: 100px;
}
.contact-us {
  margin-top: 100px;
}
.container {
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 10px;
}
.primary-color {
  color: #002fa7 !important;
}

.phone-p {
  color: #002fa7;
  font-weight: bold;
  font-size: 12px;
}
header + section {
  /* margin-top: 100px; */
}
.phone {
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #002fa7 !important;
  border-color: 10px #002fa7 !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #002fa7;
}
/* .ant-steps-item-tail::after {
  background: #002fa7 !important;
  border-color: #002fa7 !important;
} */
/* .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #002fa7 !important;
  top: -4px !important;
} */
.btn-common {
  /* width: 208px; */
  height: 45px;
  border: 1px solid #002fa7;
  background: #002fa7 !important;
  color: #fff !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
}

.dashboard-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
}
.phone-modal {
  width: 20%;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px;
  width: 100%;
  padding: 7px 11px;
}
.ant-select-arrow {
  top: 63%;
}
.ant-select-selector {
  border-radius: 10px;
}
.ant-drawer-title {
  display: flex;
  justify-content: center;
  margin-left: -20px;
}
.ant-drawer-body {
  font-size: 16px;
}

/* .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 10px !important;
} */
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  border-radius: 10px;
  min-height: 45px;
  /* overflow-x: scroll; */
}
.sectionScroll::-webkit-scrollbar {
  width: 8px;
}

.sectionScroll::-webkit-scrollbar-track {
  background-color: #e5e7eb;
  border-radius: 100px;
}

.sectionScroll::-webkit-scrollbar-thumb {
  height: 130px;
  border-radius: 100px;
  background-color: #002fa7;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.ant-radio-checked .ant-radio-inner {
  border-color: #002fa7;
}
.ant-radio-inner:after,
.ant-switch-checked {
  background-color: #002fa7 !important;
}
.ant-radio-wrapper:hover .ant-radio {
  border-color: #002fa7 !important;
}
.ant-radio-checked::after {
  border-color: #002fa7 !important;
}
.ant-input:hover {
  border-color: #d9d9d9;
}
.ant-modal-footer {
  display: none;
}
.ant-modal-body {
  padding: 0;
}
.error-med {
  color: red;
  font-size: 11px;
  margin-bottom: 8px !important;
}

.disclaimer-box p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: justify;
  color: #686868;
}
.react-calendar__tile--active {
  background: #002fa7 !important;
}
.account-section {
  padding: 10px 35px;
}
/* .circle-border {
  width: 17px;
  height: 17px;
 
  border-radius: 50%;
  flex-shrink: 0;
  background-color: #fff;
  border: 1px solid #002fa7 !important;
} */
.circle {
  width: 16px;
  height: 16px;
  /* border-radius: 50%; */
  /* flex-shrink: 0; */
  background-color: #fff;
  border: 1px solid #002fa7 !important;
}
.circleActive {
  background-color: #002fa7 !important;
}
.btnPresvrip {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: black;
}

.btnPresvrip button {
  width: 80%;
}
.btnPresvrip span {
  width: 10%;
}
.btnPresvrip:hover {
  color: white;
  background-color: #002fa7 !important;
}
.btnPresvrip:hover span {
  color: white;
}
.modalConsent {
  max-width: 90% !important;
}
label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}
label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff;
}

.WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-26 {
  padding-top: 5px !important;
}

.WAMuiChipInput-inputRoot-2.WAMuiChipInput-outlined-5 {
  padding: none !important;
}
.WAMuiChipInput-chipContainer-4 {
  color: #fff !important;
}
.label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 10px !important;
}
.ant-picker-suffix {
  padding-top: 4px !important;
  color: #002fa7 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
}
.ant-checkbox-input {
  background-color: #002fa7 !important;
}
.ant-modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  font-family: "Montserrat" !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #002fa7 !important;
  color: #fff !important;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  color: #ff0000 !important;
}

.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 15px;
}
.ant-select-multiple .ant-select-selection-item {
  height: 30px;
  line-height: 28px;
  border: none;
  background: rgba(0, 47, 167, 0.05);
  color: #002fa7;
  border-radius: 5px;
}

.ant-modal-header {
  padding-top: 30px !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.ant-modal-content {
  border-radius: 15px;
  /* margin-top: -35px !important; */
}

.inputt::placeholder {
  font-family: "adamina";
  font-size: 12px;
}
.ant-steps {
  padding-right: 43px;
}
.ant-btn-primary {
  width: 242px;
  height: 45px;
}
.borderClass {
  border: 1px solid grey !important;
}
.otpclass input {
  border: 2px solid #002fa7;
  border-radius: 5px;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}
.ant-steps {
  padding-right: 0px;
}
/* .ant-modal-content {
    margin-top: 100px !important;
  } */
.phone-modal {
  width: 90%;
}
.ant-calendar-input-wrap {
  display: none !important;
}
.ant-steps-vertical {
  flex-direction: row;
}

.ant-steps-item-description {
  display: inline-flex;
}
.ant-steps-item-tail {
  display: flex !important;
}
.span-color {
  color: #eb5757 !important;
  font-family: "Dancing Script" !important ;
}
/* .ant-table-thead{
      height: -5px;
    } */
/* .ant-table-table{
      text-align: center;
    } */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
  border-collapse: collapse;
  padding: 10px 0px;
  color: #828282;
  text-align: center;
}
/* .ant-table-thead > tr > th{
     
    } */
/* .ant-table-row-level-0{
      } */
.ant-table-row-level-0 .ant-table-cell {
  padding: 10px 25px;
  text-align: center;
  border: none;
}

/* .ant-table-tbody>tr>th{
      background-color: #333333;
      padding-left: 82px;
  
    } */

@media screen and (max-width: 600px) {
  .span-color {
    color: #eb5757;
    font-family: "Dancing Script";
  }
  .imgToShowInBgMobile {
    position: absolute;
    left: 0;
    bottom: 16px;
    z-index: -10;
    opacity: 0.4;
  }
  .imgToShowInBgMobile img {
    height: 162px;
  }
  .heroSectionResponsive {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    text-align: justify;
  }

  .headingFontResponsive {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .works-heading {
    margin-top: 40px !important;
    font-size: 22px !important;
    padding-bottom: 20px !important;
  }
  .video-content > h1 {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .heroSectionResponsive > div > p {
    width: 95% !important;
    line-height: 22px;
    padding: 0 !important;
    color: #504f4f !important;
  }
  .account-section {
    padding: 0;
  }
  .updateProfileimg {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .zIndexx {
    position: relative;
    z-index: 100;
  }
  .updateProfileimg img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .updateFieldInputs input {
    width: 100% !important;
  }
  .updateFieldInputs .ant-select-single {
    width: 100% !important;
  }
  .updateFieldInputs .updateProfAddress {
    width: 100% !important;
  }
  .updateFieldInputs > span {
    width: 100% !important;
  }
}
@media screen and (max-width: 700px) {
  .ant-steps-item-tail {
    display: none !important;
  }
  .ant-steps-item-description {
    display: none !important;
  }
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    border-collapse: collapse;
    color: #828282;
  }
  .ant-table-thead > tr > th {
    text-align: center;
  }
  /* .ant-table-row-level-0{
      } */
  .ant-table-row-level-0 .ant-table-cell {
    text-align: center;
    border: none;
  }
}
@media screen and (max-width: 420px) {
  * h1 {
    font-size: 18px !important;
  }
  * label {
    font-size: 12px !important;
  }
}
.ant-message .ant-message-custom-content {
  display: flex !important;
}

.ant-message span {
  display: inline-block !important;
  padding: 0;
}
