.prescription h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0d0d0d;
}
.disease-box {
  background-color: #ffffff;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
    0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
    0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
    0px 0px 10.5625px rgba(0, 0, 0, 0.025),
    0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
    0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
  border-radius: 20px 20px 0px 0px;
  text-align: center;

  padding: min(max(10px, 1.042vw), 20px) 10px;
  margin-bottom: 10px;
  cursor: pointer;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.category-box-pre {
  color: #29345080 !important;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
  padding: min(max(10px, 1.042vw), 0px) 0px;
  font-weight: bold;
  cursor: pointer;
  min-height: 150px;
  display: flex;
  gap: 5px;
  display: -webkit-inline-box;
  display: flex;
  width: 100%;
  align-items: center;
  overflow-x: auto;
}
.category-boxes {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  padding: min(max(10px, 1.042vw), 20px) 10px;
  font-weight: bold;
  cursor: pointer;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.category-boxes-tax {
  color: #29345080;

  font-size: min(max(10px, 1.042vw), 20px);
  font-weight: 500;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize !important;
}
.disease-box h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #002fa7;
  margin-left: 10px;
}
.detail p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #828282;
  letter-spacing: 0.3px;
}
.btn-category {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #002fa7;
  width: 100%;
  height: 44px;
  border: 1px solid #002fa7;
  border-radius: 10px;
  padding: 20px;
  justify-content: space-between;
}
.btn-category:hover {
  background-color: #002fa7;
  color: #fff;
}
.btn-category svg:hover {
  color: #fff;
}

.detail-page h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #002fa7;
}
.detail-page p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #828282;
  margin-top: 5px;
  padding-right: 15%;
}
.detail-page {
  background: rgba(237, 242, 255, 0.6);
  border: 1px solid rgba(0, 47, 167, 0.1);
  border-radius: 10px;
}
.btn-get-started {
  width: 142px;
  height: 45px;
  background: #002fa7 !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 15px;
}
.detail-text-box {
  width: 32%;
  display: flex;
  flex-direction: column;
  place-self: flex-start;
  background: #ffffff;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
    0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
    0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
    0px 0px 10.5625px rgba(0, 0, 0, 0.025),
    0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
    0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
  border-radius: 10px;
}
.detail-text-box ul li {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: justify;
  color: #686868;
  letter-spacing: 0.3px !important;
}
.detail-text-box h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}
.login-box {
  width: 500px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.07),
    0px 15.5556px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 9.24444px 25.4815px rgba(0, 0, 0, 0.0425185),
    0px 4.8px 13px rgba(0, 0, 0, 0.035),
    0px 1.95556px 6.51852px rgba(0, 0, 0, 0.0274815),
    0px 0.444444px 3.14815px rgba(0, 0, 0, 0.0168519);
  border-radius: 10px;
}
.login-box h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0d0d0d;
}
.login-box p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.login-box h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #002fa7;
}
.btn-login {
  width: 350px;
  height: 45px;
  border: 1px solid #002fa7;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #002fa7;
}
.btn-register {
  width: 350px;
  height: 45px;
  background: #002fa7 !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.btn-guest {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #002fa7;
}
.btn-cancel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #eb5757;
}
.disease-box-color {
  background: #002fa7;
  /* color: #fff; */
  border: 1px solid rgba(0, 47, 167, 0.1);
}
.disease-box-color h3 {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .login-box {
    width: auto;
  }
  .disease-box h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #002fa7;
    margin-left: 10px;
  }
  .mainCard {
    margin-top: 25%;
  }
  .detail p {
    font-family: "Adamina";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    text-align: justify;
    color: #828282;
    margin-top: 5px;
    letter-spacing: 0.3px !important;
  }
  .btn-get-started {
    width: 130px;
    height: 30px;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .detail-page h1 {
    font-size: 18px !important;
  }
  .detail-text-box {
    width: 100%;
  }
  .prescription {
    margin-top: 100px !important;
  }
}
