@import "../../../style/variables";
@import "../../../style/mixins";

.notValidSlot {
  background-color: grey;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 7px;
  border-radius: 5px;
  color: white;
}

.slotAlreadyBooked {
  background-color: tomato;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 7px;
  border-radius: 5px;
  color: white;
}

.payment-page-table-main {
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  border: 1px solid #002fa7;
  border-radius: 5px;

  thead tr {
    border: 1px solid #002fa7;
    background-color: #002fa71a;
    text-align: center;
    color: #002fa7;
  }

  td {
    vertical-align: middle !important;
    text-align: center;
  }

  th,
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .text-right {
    text-align: end;
  }
}
