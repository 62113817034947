@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 80px;
  height: 80px;
  border: 10px solid #ffffff;
  border-top: 10px solid #002fa7;
  border-bottom: 10px solid #002fa7;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  /* position: absolute; */
  z-index: 1000;
}
.Spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #ffffff;
  border-top: 5px solid #002fa7;
  border-bottom: 5px solid #002fa7;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 1000;
}
.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #b6aeae3b;
}
.loader-box {
  height: 300px;
  width: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 5px solid #002fa7; */
}

/* @keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  width: 80px;
  height: 80px;
  border: 10px solid #ffffff;
  border-top: 10px solid #002fa7;
  border-bottom: 10px solid #002fa7;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  z-index: 10000;
}
.Spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #ffffff;
  border-top: 5px solid #002fa7;
  border-bottom: 5px solid #002fa7;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 5000;
}
.spinnerContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #b6aeae3b;
}
.loader-box {
  height: 300px;
  width: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

} */
