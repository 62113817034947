@import "../../style/variables";
@import "../../style/mixins";

.aco-primary-button {
  background-color: $primaryColor;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding-inline: 15px;
  border-radius: 7px;
  color: white;
  font-family: $secondaryFont;
  @include flex(row, center, center, 8px);
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #002480;
  }
}
