.consultationContentMain {
      width: 70%;

}
.boxesContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
}
.firstBoxConsul {
      width: 70%;
      height: 136px;
      box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05), 0px 0px 38.0671px rgba(0, 0, 0, 0.037963), 0px 0px 20.7037px rgba(0, 0, 0, 0.0303704), 0px 0px 10.5625px rgba(0, 0, 0, 0.025), 0px 0px 5.2963px rgba(0, 0, 0, 0.0196296), 0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
border-radius: 10px;
padding: 30px;
}
.secondBoxConsul {
      padding: 30px;
      width: 25%;
      background: #FFFFFF;
/* sm-card */
height: 136px;


box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05), 0px 0px 38.0671px rgba(0, 0, 0, 0.037963), 0px 0px 20.7037px rgba(0, 0, 0, 0.0303704), 0px 0px 10.5625px rgba(0, 0, 0, 0.025), 0px 0px 5.2963px rgba(0, 0, 0, 0.0196296), 0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
border-radius: 10px;
}

.firstBoxConsul h5 {

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #828282;
}
.firstBoxConsul p {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
margin-top: 10px;
font-size: 16px;
line-height: 23px;
/* or 144% */


/* Orange */

color: #F2994A;
}
.secondBoxConsul h5 {

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;




color: #828282;

}
.secondBoxConsul p {

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 32px;


color: #0D0D0D;
}
.secondBoxConsul p span {
      font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 20px;




color: #828282;
}
.consulHeader {
    margin-top: 30px;
      display: flex;

}
.consuleHeaderContent {
      display: flex;
      width: 100%;

}
.consuleHeaderContent h1 {
      height: 36px;
      margin-right: 20px;
      font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
color: #828282;
padding: 10px;

}
.consulHeaderActive {
      background-color: #002FA7;
      color: white !important;
      text-align: center;
      border-radius: 10px;

}
.timeLineConsulMain {
      box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05), 0px 0px 38.0671px rgba(0, 0, 0, 0.037963), 0px 0px 20.7037px rgba(0, 0, 0, 0.0303704), 0px 0px 10.5625px rgba(0, 0, 0, 0.025), 0px 0px 5.2963px rgba(0, 0, 0, 0.0196296), 0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
      width: 100%;
      padding: 30px;
      border-radius: 10px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
}
.counselDetails {
      display: flex;
      flex-direction: column;

}
.counselDetails h3 {
      font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;

/* Gray 3 */

color: #828282;
}
.detailIconCons {
      width: 110px;
      margin-right: 30px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(237, 242, 255, 0.6);     
      border-radius: 10px;
}
.consulDetailsContent {
      display: flex;
      /* justify-content: -between; */

}
.detailOnSIngleLINE {
      display: flex;
}
.detailsAllContent {
      width: a;
      display: flex ;
      flex-direction: column;
      
}
.singleContentDetails {
display: flex;
margin-right: 40px;
}
.singleContentDetails .detailQ{
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 21px;
margin-right: 15px;
/* identical to box height, or 175% */


/* Gray 3 */

color: #828282;
}
.singleContentDetails .detailA {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 21px;
/* identical to box height, or 175% */


/* #0D0D0D */

color: #0D0D0D;
}
.singleContentDetails h5 {
      font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 21px;
color: #002FA7;
}
.docDetailsContainer {
      display: flex;
      margin-top: 20px;


}
.docDetailInConsul h3 {

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #828282;
}
.docImageFirst img
{
      width: 123px;
      height: 123px;
      border-radius: 10px;
}
.docImageFirst {
      margin-right: 20px;
}
.docDetailsContent {
      display: flex;

}
.singleDocConsul {
      display: flex;

}
.singleDocConsul .docDetailsQ {

font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-right: 40px;

color: #828282;
}
.singleDocConsul .docDetailsA {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #0D0D0D;
}