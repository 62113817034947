.mainConsultationContainer {
      width: 100%;
      
}
.mainConsultationContainer > h1 {
      text-align: center;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 24px;
      color: #0D0D0D;

}
.mainSideCom {
      width: 30%;
}
.consulContent {
      display: flex;
}
.profileBar {
      width: 242px;
      height: 474px;
      box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05), 0px 0px 38.0671px rgba(0, 0, 0, 0.037963), 0px 0px 20.7037px rgba(0, 0, 0, 0.0303704), 0px 0px 10.5625px rgba(0, 0, 0, 0.025), 0px 0px 5.2963px rgba(0, 0, 0, 0.0196296), 0px 0px 2.55787px rgba(0, 0, 0, 0.012037);

      border-radius: 10px;
}
.videoLinkBar {
      margin-top: 20px;
      width: 242px;
      height: 220px;
      box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05), 0px 0px 38.0671px rgba(0, 0, 0, 0.037963), 0px 0px 20.7037px rgba(0, 0, 0, 0.0303704), 0px 0px 10.5625px rgba(0, 0, 0, 0.025), 0px 0px 5.2963px rgba(0, 0, 0, 0.0196296), 0px 0px 2.55787px rgba(0, 0, 0, 0.012037);

      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

}
.imageNname {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
     
      height: 40%;
}
.borderOnlyConsul {
      height: 1px;
      width: 100%;
      border-top: 1px solid #E0E0E0;
}
.imageNname >  img{
      width: 82px;
      height: 82px;
      border-radius: 50%;

}
.imageNname >  h3 {
      margin-top: 10px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
    
}
.imageNname >  p {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 21px;
}
.profileDetailConsul {
     
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

}
.patientDetailsConusl {
      display: flex;
      flex-direction: column;
      width: 80%;
      

}
.firstQ {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-top: 20px;
color: #828282;
}
.firstA {
      font-family: 'Adamina';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 21px;


color: #0D0D0D;

}
.btnMessageConsul {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

}
.btnMessageConsul button {
      width: 182px;
      color: #002FA7;
height: 45px;
background: #FFFFFF;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;

border: 1px solid #002FA7;
border-radius: 10px;
}
.VideoLinkBarContent h3 {
      font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #828282;
}
.VideoLinkBarContent {
      width: 80%;
}
.videoLinksImages {
      display: flex;
      position: relative;
      margin-top: 10px;
      width: 100%;
     
}
.videoLinksImages img {
      width: 79px;
      height: 79px;
      border-radius:50%;
}
.secondImageConsul {
      position: absolute;
      left: 50px;
      z-index: 1000;
      top: 3px;
      outline: 3px solid #FFFFFF;
      width: 82px !important;
      
}
.VideoLinkBarContent h2 {
      margin-top: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
     
      font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #002FA7;
}
.VideoLinkBarContent h2 span {
      margin-right: 5px;
}
.videoCallIcons {
      position: absolute;
      right: 51px;
      z-index: 100003;
      bottom: 0;
}
