.medicine-h {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #828282;
}
.line {
  border-top: 3px solid #e0e0e0;
}
.textDoc_detail{
  display: grid;
}

@media screen and (max-width:700px) {
  .DocDetailRes{
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: 0px auto;
  }
  .feildRes{
    width: 100%;
  }
}
/* .medicine-list {
  height: 230px;
  overflow-y: scroll;
}
.medicine-list h4 {
  font-family: "Adamina" !important;
}
.medicine-list h1 {
  font-family: "Montserrat" !important;
} */
