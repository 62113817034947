.contact-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
}

.contact-us-error-status {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 29px;
  color: #ff2020;
  margin-top: 20px;
  text-align: center;
}

.ant-input-textarea .ant-input {
  border-radius: 10px !important;
}
