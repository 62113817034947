.account-section h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0d0d0d;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.divider {
  height: 300px;
  width: 1px;
  background-color: #e5eaf6;
  margin-top: 50px;
}
.profile-img {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #686868;
}
.browse-image {
  width: 133px;
  height: 31px;
  /* left: 118px;
  top: 424px; */
  background: #e5eaf6;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #002fa7;
}
.profile-image-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #686868;
}
.account-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-item-account {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.update-password-account-button {
  margin-left: auto;
  margin-right: 15%;
}

@media screen and (max-width: 700) {
  .divider {
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .updateProfileimg {
    width: 100%;
    flex: 1;
    flex-basis: 100%;
    justify-content: center;
  }
  .updateFieldInputs {
    width: 49%;
    margin-top: 20px;
  }
  #startNow .md\:grid-cols-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.toolbar {
  width: 100% !important;
  height: 70px;
  left: 0px;
  z-index: 899;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.toolbar .toolbar-navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 35px;
  width: 100%;
}
.nav-items li {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}
.toolbar-logo h2 {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1d4069;
  cursor: pointer;
}

.toolbar .toolbar-navigation-items li {
  padding: 5px 15px;
}
.toolbar .toolbar-logo {
  margin-left: 10px;
  display: flex;
}
.logo-text {
  margin-left: 10px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #1d4069;
}

.toolbar .toolbar-logo a {
  text-decoration: none;
}
.toolbar .toolbar-navigation-items a {
  text-decoration: none;
  color: #0d0d0d;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.toolbar .toolbar-navigation-items a:hover,
.toolbar .toolbar-navigation-items a:active {
  /* opacity: 0.6; */
  color: #eb5757 !important;
}

.spacer {
  flex-grow: 1;
}

.login-btn-nav {
  width: 110px;
  height: 45px;
  color: #0d0d0d !important;
  margin-right: 10px;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  font-family: "poppins";
}
.register-btn-nav {
  width: 120px;
  height: 45px;
  color: #fff;
  border: 1px solid #002fa7;
  background: #002fa7 !important;
  border-radius: 10px;
  font-family: "poppins";
  font-size: 16px;
  font-weight: 700;
}
.image-editor-modal-content-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 700px;
}
.image-editor-modal-content-container .image-save-button-in-editor {
  background-color: #002fa7;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  width: 90px;
  border-radius: 7px;
  color: white;
  font-family: "poppins";
  margin-inline: auto;
}
.image-editor-modal-content-container .heading-editor {
  font-size: 17px;
  font-weight: 600;
}
.image-editor-controls {
  position: absolute;
  bottom: 90px;
  left: 30px;
  display: flex;
  gap: 10px;
}
.image-editor-controls .image-editor-button {
  font-size: 14px;
  color: white;
  background-color: #002fa7;
  height: 30px;
  width: 30px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-editor-controls .image-editor-button .anticon {
  margin: 0;
  padding: 0;
}

@media (max-width: 760px) {
  .toolbar-navigation-items {
    display: none;
  }
  .image-editor-modal-content-container {
    width: 100%;
    padding: 20px 10px;
  }
}
@media (min-width: 761px) {
  .toggle-button {
    display: none;
  }
}
@media (max-width: 420px) {
  .toolbar-logo {
    width: auto;
    height: auto;
  }
  .toolbar .toolbar-navigation {
    padding: 0px;
  }
  .toggle-button img {
    width: 25px;
    height: auto;
  }
}
@media (max-width: 350px) {
  .toolbar {
    height: auto;
  }
  .toolbar .toolbar-navigation {
    padding: 15px 10px;
  }
}
