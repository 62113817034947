.toolbarr {
  position: fixed !important;
  width: 100%;
  height: 70px;
  top: 0px;
  left: 0px;
  z-index: 899;
  background-color: white;
}
.bgg-transparent {
  background-color: transparent !important;
}
.shadows {
  /* box-shadow: 0px 17px 10px rgb(0 0 0 / 1%),
      0px 11.0185px 26.8519px rgb(0 0 0 / 1%),
      0px 6.54815px 15.4815px rgb(0 0 0 / 1%), 0px 3.4px 13px rgb(0 0 0 / 1%),
      0px 1.38519px 6.51852px rgb(0 0 0 / 1%), 0px 0.314815px 1; */
  display: initial;
  background: #fff;
}
.toolbarr .toolbar-navigationn {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 35px;
}

.nav-items li {
  font-weight: 600;
  padding-right: 10px;
  font-size: 14px;
  line-height: 19px;
}

.toolbarr .toolbar-navigation-itemss ul {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  margin-top: 10px;
  gap: 10px;
}
.toolbar-logo h2 {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1d4069;
  cursor: pointer;
}

.toolbarr .toolbar-navigation-items li {
  padding: 5px 15px;
}
.toolbarr .toolbar-logo {
  margin-left: 10px;
  display: flex;
}
.logo-text {
  margin-left: 10px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #1d4069;
}

.toolbarr .toolbar-logo a {
  text-decoration: none;
}
.toolbarr .toolbar-navigation-itemss a {
  text-decoration: none;
  color: #828282;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.color-change {
  color: yellow !important;
}
.toolbarr .toolbar-navigation-itemss a:hover,
.toolbarr .toolbar-navigation-itemss a:active {
  opacity: 0.6;
  /* color: black !important; */
}
.nav-dropdown {
  background: #ffffff;

  border-radius: 15px;
  width: 181px;
  height: 190px;
  margin-top: 10px;
  margin-left: -120px;
  padding: 15px;
}
.nav-dropdown a {
  text-decoration: none !important;
  color: none;
  font-family: "Poppins", sans-serif !important;
}
.nav-dropdown h4:hover {
  color: #1d4069;
}
.nav-dropdown h4 {
  cursor: pointer !important;
  font-family: "Poppins", sans-serif !important;
}

.spacer {
  flex-grow: 1;
}

.login-btn-nav {
  width: 110px;
  height: 45px;
  color: #0d0d0d !important;
  margin-right: 10px;
  text-decoration: none;
  font-weight: 700;
}
.nav-line {
  border-right: 3px solid #381050;
  opacity: 0.15;
  width: 15px;
  height: 45px;
  padding-left: 20px;
  /* padding-right: 50px; */
}
.register-btn-nav {
  width: 120px;
  height: 45px;
  color: #fff;
  border: 1px solid #002fa7;
  background: #002fa7 !important;
  border-radius: 10px;
}
a {
  text-decoration: none !important;
  /* color: #381050 !important; */
}
a p {
  padding-top: 15px;
  font-weight: normal;
  text-align: center;
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-close {
  margin-right: 0;
}

.notification-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(255, 33, 33);
  position: absolute;
}

.all-notifications-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 4px;
}

.notification-item {
  background-color: rgb(241, 241, 241);
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 320px;
}
.container-single {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.close-icon-notification {
  cursor: pointer;
}
.close-icon-notification:hover svg {
  fill: #ff2020;
}
.notification-item:hover {
  background-color: #dfdfdf;
}
.notification-item span {
  font-size: 12px;
  font-weight: 600;
}
.notification-item i {
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
}
.see-all-notifications-button {
  margin-inline: auto;
  font-weight: 500;
  font-size: 13px;
}
.unread-item {
  background-color: rgb(214, 232, 248);
}
.red-color-notification {
  color: red;
}
@media (max-width: 760px) {
  .toolbar-navigation-itemss {
    display: none;
  }
  .toolbarr .toolbar-navigationn {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 10px;
  }
}
@media (min-width: 761px) {
  .toggle-button {
    display: none;
  }
  .notification-dot {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .toolbar-logo {
    width: auto;
    height: auto;
  }
  .toolbarr .toolbar-navigationn {
    padding: 0px;
  }
  .toggle-button img {
    width: 25px;
    height: auto;
  }
  .nav-dropdown {
    margin-left: -125px;
  }
}
@media (max-width: 350px) {
  .toolbarr {
    height: auto;
  }
  .toolbarr .toolbar-navigationn {
    padding: 15px 10px;
  }
}
