.consultation-sec {
  background-color: #fff;
  width: 85%;
  margin: 10px 82px;
}

.upperContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 19px; */
  margin-top: 5%;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 15px;
  font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #012fa7;
}

.ant-select-arrow {
  top: 55%;
}

.headings-consultation-box {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
}

div[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.Type {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.status-inp {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(33, 150, 83, 0.1);
  border-radius: 4px;
}

.status-text-inp {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #239653;
}

.status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(242, 153, 74, 0.1);
  border-radius: 4px;
}

.status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #f2994a;
}

.missed-status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(242, 85, 74, 0.1);
  border-radius: 4px;
}

.missed-status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #c51802;
}

.completed-status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(88, 242, 74, 0.1);
  border-radius: 4px;
}

.completed-status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #05791a;
}

.review-status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(222, 242, 74, 0.1);
  border-radius: 4px;
}

.review-status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #d88303;
}

.payment-status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(13, 13, 13, 0.1);
  border-radius: 4px;
}

.payment-status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #0d0d0d;
}

.complaint-status-box {
  width: 137px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(242, 85, 74, 0.1);
  border-radius: 4px;
}

.complaint-status-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #c51802;
}

.upperContainer > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.upperContainer > span {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #828282;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
}

.category-images {
  /* height: 130px;
  width: auto; */
  /* height: 100%; */
}

.category-boxx {
  /* width: 350px; */

  height: 145px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 17px 80px rgba(0, 0, 0, 0.03),
    0px 11.0185px 46.8519px rgba(0, 0, 0, 0.0227778),
    0px 6.54815px 25.4815px rgba(0, 0, 0, 0.0182222),
    0px 3.4px 13px rgba(0, 0, 0, 0.015),
    0px 1.38519px 6.51852px rgba(0, 0, 0, 0.0117778),
    0px 0.314815px 3.14815px rgba(0, 0, 0, 0.00722222);
  border-radius: 10px;
}

.category-boxx h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #0d0d0d;
}

.category-boxx h5 {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #828282;
  margin-top: 6px;
  padding-right: 5px;
  /* word-spacing: 2px; */
  letter-spacing: 0.2px;
}

.card-PictureAC {
  padding-bottom: 8px;
  padding-left: 12px;
}

.category-contentt {
  /* width: 65%;
  padding-left: 5px;
  padding-top: 10px; */
}

.category-buttonn {
  width: 171px;
  height: 36px;
  border-radius: 10px;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  margin-top: 20px;
  cursor: pointer;
}

.blue-color {
  background-color: #012fa7;
}

.black-color {
  background-color: #0d0d0d;
}

.red-color {
  background-color: #eb5757;
}

.Action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* gap: 10px; */
}

.my_activity {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  padding-top: 24px;
}

/* .ant-table-thead{

  padding: 20px;
} */
/* .tableCSS {
  margin: '40px 120px';
  backgroundColor: 'white',
}
   .tableCSS>table {
  
  }
  .tableCss.thead >tr > th{
    backgroundColor: 'darkblue';
    color: 'white',
  }
   .tableCSS>thead >tr {
    borderWidth: '2px';
    borderColor: 'yellow';
    borderStyle: 'solid'
  } */

.mainUpcoming {
  margin-top: 1%;
  margin-right: 3%;
  width: 100%;
  border-radius: 20px;
  border: 1px solid lightgrey;
  padding: 10px;
}

.mycontainer {
  margin-top: 20px;
}

.myrow {
  display: flex;
  flex-wrap: wrap;
}

.myborder {
  display: flex;
  align-items: center;
  /* padding: 10px; */
}

.myProfile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 5%;
  object-fit: cover;
  text-align: center;
  align-self: center;
}

@media screen and (max-width: 760px) {
  .consultation-sec {
    background-color: #fff;
    width: 92%;
    margin: 10px 5px;
  }

  .upperContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 15px; */
  }

  .upperContainer > p {
    font-weight: 700;
    font-size: 22px;
  }

  .upperContainer > span {
    font-family: "Adamina";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    color: #828282;
  }

  .card-PictureAC {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 540px) {
  .category-boxx {
    width: 350px !important;
    height: 164px;
  }

  .ant-table-content {
    width: 100%;
    overflow-x: auto;
  }

  .upperContainer {
    margin-top: 60px;
  }

  .restFilterIcon {
    /* display: none; */
    margin-right: 10px;
  }

  .searchBydoc {
    width: 350px;
  }

  .videoFilter {
    width: 152px !important;
  }

  .category-boxx {
    padding-top: 0px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .category-buttonn {
    margin-top: 10px;
  }

  .myResponsiveTable {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin: 0px auto;
  }
}
