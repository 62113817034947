.details-container {
  width: 100%;
  height: 10%;
  /* margin-left: 130px; */
  padding: 0% 6.5%;
  margin-top: 100px;
}
.detail-container {
  width: 100%;
  height: 10%;
  padding: 0% 6.5%;
}
.description-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nodatayet {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.notes-card {
  /* position: absolute; */
  width: 92%;
  height: 250px;
  top: 55px;
  background-color: #ffffff;
  box-shadow: 0px 0px 65px rgb(0 0 0 / 5%), 0px 0px 38.0671px rgb(0 0 0 / 4%),
    0px 0px 20.7037px rgb(0 0 0 / 3%), 0px 0px 10.5625px rgb(0 0 0 / 3%),
    0px 0px 5.2963px rgb(0 0 0 / 2%), 0px 0px 2.55787px rgb(0 0 0 / 1%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  left: 55px;
}
.review-card {
  /* position: absolute; */
  width: 92%;
  /* margin-top: 55px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 65px rgb(0 0 0 / 5%), 0px 0px 38.0671px rgb(0 0 0 / 4%),
    0px 0px 20.7037px rgb(0 0 0 / 3%), 0px 0px 10.5625px rgb(0 0 0 / 3%),
    0px 0px 5.2963px rgb(0 0 0 / 2%), 0px 0px 2.55787px rgb(0 0 0 / 1%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  /* margin-left: 55px; */
}
.bottom-review-card {
  width: 400px;
  height: 100px;
  background: rgb(228, 227, 227);
  border-radius: 10px;
  margin: 10px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CollapsMed {
  width: 550px;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(242, 242, 242, 1);
  margin-top: 8px;
  padding: 10px 0px;
  border-color: #828282;
  border-radius: 6px;
  padding-left: 4.5%;
  padding-right: 3.5%;
  margin-left: 24px;
}
.CollapsMed p {
  color: #828282;
  font-size: 14px;
  font-weight: 600;
}
.medicine-listt {
  /* height: 230px; */
  /* overflow-y: scroll; */
}
.medicine-list h4 {
  font-family: "Adamina" !important;
}
.medicine-list h1 {
  font-family: "Montserrat" !important;
}

.desc-leftside-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desc-leftside-sec > h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #002fa7;
}
.desc-leftside-sec > h5 > small {
  color: #828282;
}
.desc-leftside-sec > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0d0d0d;
}
.desc-rightside-sec {
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.secondSectionWidth {
  width: 70%;
  margin-left: 50px;
}

.request-button {
  height: 30px;
  /* width: 112px;
  background: #002fa7;
  font-weight: 600;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  cursor: pointer; */
}
.request-button > p {
  padding: 8px;
  font-size: 12px;
}
.disable-request-button {
  width: 130px;
  height: 30px;
  background: #002fa7;
  font-weight: 600;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  pointer-events: none;
  opacity: 0.7;
}
.disabled-request-button > p {
  padding: 8px;
  font-size: 12px;
}
.download-preps {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
}
.message-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.condition-sec {
  background: #e5eaf6;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
}
.condition-sec > h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #0b32a3;
  margin-left: 6px;
  /* padding: 0px 6px; */
}
.message-sec > h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #002fa7;
}
.bottomNav-list > ul {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.bottom-sectionss {
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
}
.tabs-section-menu {
  padding-top: 12px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 10px;
}
.tabs-section-menu > p {
  cursor: pointer;
}

.activeTab {
  color: #0b32a3;
  border-bottom: 2px solid #0b32a3;
  font-weight: 600;
}

.lower-section {
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  /* height: 1050px; */
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  margin-top: 12px;
  padding: 20px 0px;
}
.lower-booking-sectionss {
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  margin-top: 12px;
}
.bottom-card-section {
  width: 92%;
  background-color: #ffffff;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
    0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
    0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
    0px 0px 10.5625px rgba(0, 0, 0, 0.025),
    0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
    0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.headings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #a6a6a6;
  padding: 30px;
}
.Right-side-content {
  margin-top: 90px;
  margin-right: 100px;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.accepted {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  /* #002FA7 */

  color: #002fa7;
}
.pending {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #828282;
}
.stepper-desc {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: justify;
  color: #828282;
}
.booking-card {
  width: 92%;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
    0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
    0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
    0px 0px 10.5625px rgba(0, 0, 0, 0.025),
    0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
    0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
  border-radius: 10px;
}
.booking-nav-bar {
  display: flex;
  align-items: center;
  gap: 12px;
  /* width: 73%; */
  margin-left: 33px;
}
.booking-nav-content {
  background: #f2f2f2;
  height: 35px;
  /* border: 1px solid #828282; */
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 8px 20px; */
  cursor: pointer;
  color: #555252;
}
.booking-nav-content-active {
  background: #0b32a2;
  color: #ffffff;
  height: 35px;
  /* border: 1px solid #828282; */
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0px 7px; */
  cursor: pointer;
}
.booking-nav-content-active > h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: white;
  padding: 20px;
  margin-bottom: 0px !important;
}
.booking-nav-content > h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: black;
  padding: 20px;
}
.active-booking-nav-headings {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background-color: #0d0d0d;

  /* #FFFFFF */

  color: white;
}
.profile-image-user {
  width: 123px;
  height: 123px;
  border-radius: 10px;
  object-fit: cover;
}
/* .image-cont{
   
} */
.personal-info {
  display: flex;
  margin-left: 29px;
  margin-top: 30px;
  /* height: 350px; */
  gap: 50px;
}
.questionss {
  width: 120px;
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #828282;
}
.answer {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
  /* width: 100%; */
  /* margin-left: 2%; */
}

.diseases {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #0d0d0d;
}
.prescription-second-cont {
  display: flex;
  /* gap: 10px; */
  padding-top: 5px;
  justify-content: end;
}
.prescriptionDetails-cont {
  /* display: flex; */
  padding: 20px;
  /* justify-content: space-between; */
}
.prep-left-side-cont {
  width: 311px;
  height: 122px;
  background: rgba(237, 242, 255, 0.6);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prep-left-side-contt {
  padding-left: 20px;
}
.prep-left-side-cont > svg {
  padding-right: 20px;
}
.prep-left-side-contt > h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
}
.prep-left-side-contt > h5 {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}
.prep-left-side-contt > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #002fa7;
}
.download-sec {
  width: 110px;
  height: 122px;
  background: rgba(237, 242, 255, 0.6);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-right: 85px; */
}
.download-secc {
  width: 110px;
  height: 122px;
  background: rgba(237, 242, 255, 0.6);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.download-prep {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  /* #002FA7 */

  color: #002fa7;
}
.download-secc {
  width: 80px;
  height: 80px;
  background: rgba(237, 242, 255, 0.6);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.med-middle-sec {
  /* display: flex; */
  margin-top: 20px;
  margin-left: 25px;
  flex-wrap: wrap;
}
.mainPrescrib {
  display: flex;
  justify-content: space-between;
}
.medicine-list h4 {
  font-family: "Adamina" !important;
}
.medicine-list h1 {
  font-family: "Montserrat" !important;
}
.DoseCard {
  /* display: flex; */
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid #e5e7eb;
  margin-top: 10px;
}
.leftSidePrescription {
  width: 45%;
}
.prescribRightInstr {
  width: 45%;
}
.prescribContent {
  /* display: flex; */
  margin-top: 10px;
  /* gap: 50px; */
  width: 100%;
}
.firstSectionWidth {
  width: 23%;
}
.prscribHeading {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #959393;
  width: 50px;
}
.prscribData {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #555252;
  margin-top: 3px;
  /* width: 112px; */
}
.CollapsMed {
  width: auto;
  max-width: 875px;
  /* height: 54px; */
  max-height: 360px;
  overflow: auto;
  background: #f2f2f2;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 20px;
  padding: 10px 0px;
  /* border-color: #828282; */
  border-radius: 18px;
  padding-left: 4.5%;
  padding-right: 3.5%;
}
.CollapsMed p {
  color: #828282;
  font-size: 14px;
  font-weight: 600;
}
.prescribTimes {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.med-headings {
  margin-left: 10px;
}
.med-headings > h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  color: #0d0d0d;
}

.med-headings > ul > li {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */

  /* #0D0D0D */

  color: #0d0d0d;
}
.med-headings > p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
  width: 100%;
}
.medications-list > p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
}
.medications-list {
  /* display: flex; */
  width: 400px;
  flex-wrap: wrap;
  gap: 6px;
}
.MuiStepLabel-iconContainer .Mui-completed {
  color: rgb(2, 207, 2) !important;
}
.prescription-request-form-in-overview {
  margin-block: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.prescription-request-form-in-overview input {
  width: 100%;
  border: 1px solid #828282;
  height: 35px;
  border-radius: 5px;
  padding-inline: 10px;
}
@media screen and (max-width: 686px) {
  .firstSectionWidth {
    width: 40%;
  }
  .secondSectionWidth {
    margin-left: 10px;
    width: 65%;
  }
}
@media screen and (max-width: 700px) {
  .details-container {
    width: 100%;
    height: 10%;
    margin-left: 0px;
    padding: 0px 12px;
  }
  .mainPrescrib {
    flex-direction: column;
  }
  .leftSidePrescription {
    width: 100%;
  }
  .prescribRightInstr {
    width: 100%;
  }
  .headings {
    padding-left: 0px !important ;
  }
  .CollapsMed {
    /* width: fit-content; */
    background-color: rgba(242, 242, 242, 1);
    margin-top: 8px;
    max-height: 200px;
    padding: 10px 0px;
    border-color: #828282;
    border-radius: 6px;
    padding-left: 4.5%;
    padding-right: 3.5%;
    margin-left: 24px;
  }
  .description-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20%; */
    padding: 0px 4px;
  }
  .tabs-section-menu {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    gap: 10px;
  }
  .desc-leftside-sec {
    padding-top: 10px;
    /* padding-left: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .desc-leftside-sec > h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #002fa7;
  }
  .desc-leftside-sec > h5 > small {
    color: #828282;
  }
  .lower-section {
    width: 100%;
    background-color: rgba(242, 242, 242, 1);
    height: auto;
    position: relative;
    padding: 15px;
  }
  .lower-booking-sectionss {
    width: 100%;
    background-color: rgba(242, 242, 242, 1);
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .bottom-card-section {
    width: 95%;

    background-color: #ffffff;
    box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
      0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
      0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
      0px 0px 10.5625px rgba(0, 0, 0, 0.025),
      0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
      0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
  }
  .steppers-container {
    width: 50%;
  }
  .Right-side-content {
    margin-top: 60px;
    margin-right: 0px;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .accepted {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 18px;

    /* #002FA7 */

    color: #002fa7;
  }
  .pending {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #828282;
  }
  .stepper-desc {
    font-family: "Adamina";
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
    color: #828282;
  }
  .booking-card {
    /* position: absolute; */
    width: 100%;
    padding: 10px;

    background: #ffffff;
    box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
      0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
      0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
      0px 0px 10.5625px rgba(0, 0, 0, 0.025),
      0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
      0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
    border-radius: 10px;
  }
  .booking-nav-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin-left: 0 !important;
  }
  .booking-nav-content-active {
    /* width: 160px; */
  }
  .booking-nav-content {
    background: #ffffff;
    height: 25px;
    border: 1px solid #828282;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    /* width: 160px; */
  }
  .personal-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    gap: 10px;
  }
  .profile-image-user {
    width: 123px;
    height: 123px;
    border-radius: 10px;
  }
  .prescriptionDetails-cont {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 10px;
  }
  .medications-list {
    display: flex;
    width: 250px;
    flex-wrap: wrap;
    gap: 5px;
  }
  .review-card {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 65px rgb(0 0 0 / 5%), 0px 0px 38.0671px rgb(0 0 0 / 4%),
      0px 0px 20.7037px rgb(0 0 0 / 3%), 0px 0px 10.5625px rgb(0 0 0 / 3%),
      0px 0px 5.2963px rgb(0 0 0 / 2%), 0px 0px 2.55787px rgb(0 0 0 / 1%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
  .bottom-review-card {
    width: 302px;
    height: 100px;
    background: rgb(228, 227, 227);
    border-radius: 10px;
    margin: 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .notes-card {
    /* position: absolute; */
    width: 100%;
    height: 200px;
    top: 55px;
    background-color: #ffffff;
    box-shadow: 0px 0px 65px rgb(0 0 0 / 5%), 0px 0px 38.0671px rgb(0 0 0 / 4%),
      0px 0px 20.7037px rgb(0 0 0 / 3%), 0px 0px 10.5625px rgb(0 0 0 / 3%),
      0px 0px 5.2963px rgb(0 0 0 / 2%), 0px 0px 2.55787px rgb(0 0 0 / 1%);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    left: 55px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .lower-booking-sectionss .booking-card .booking-nav-bar {
    width: 100%;
    margin-left: 0;
  }
  .lower-booking-sectionss .personal-info {
    flex-direction: column;
    margin-left: 0;
  }
  .lower-booking-sectionss .name-sec {
    width: 100%;
  }
  .lower-booking-sectionss .name-sec .questionss {
    min-width: 150px;
    width: 150px;
  }
  .lower-section .booking-card .review-container > div {
    width: 100%;
  }
}
