.login-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0d0d0d;
}
.login-paragraph {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #686868;
  margin-top: 10px;
}
.ant-input-password-icon {
  padding-bottom: 0px;
}
.login-inputs {
  /* width: 363px; */
  height: 45px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.login-btn {
  width: 363px;
  height: 45px;
  background: #002fa7 !important;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  margin-top: 50px;
}
.main-login-button {
  margin-top: 50px;
  width: 100%;
}
.signup-btn {
  /* width: 120px; */
  /* padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  background: #002fa7 !important;
  border-radius: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px; */
  margin-top: 30px;
}
.line-hei {
  line-height: 2px !important ;
}
.guest-btn {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #002fa7;
  margin-top: 30px;
  height: 45px;
}
.second-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #a4d0db;
  height: 41px;
  cursor: pointer;
  padding-top: 20px;
  text-decoration: none !important;
}

.second-btn p {
  text-decoration: none !important;
}

.back-color {
  background-color: #edf2ff;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
} */

.forget {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: end;
  color: #002fa7;
}
.remember-me {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 21px;
  color: #0d0d0d !important;
  margin-left: 10px;
}
.remember-mee {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 21px;
  color: #0d0d0d !important;
  /* margin-left: 10px; */
}
.signup {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #686868;
}
.signup a {
  color: #002fa7 !important;
}

@media screen and (max-width: 480px) {
  .back {
    /* background-image: url("../../assets/Images/AmericanClinic\ Online.svg"); */
    background-repeat: no-repeat;
  }
  .login-btn {
    width: 100%;
  }
}
