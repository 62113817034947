@import "../../style/variables";
@import "../../style/mixins";

.messages-main-container-component {
  width: 100%;
  @include flex(row, "", flex-start, 25px);
  padding: 100px 40px 0px 40px;
  height: calc(100vh - 100px);

  .no-messages-in-chat-container {
    width: 100%;
    @include flex(column, center, center);
    height: 100%;
    & > div {
      @include flex(column, center, center);
    }
  }

  @include phoneOnly {
    padding: 90px 10px 0px 10px;
    height: calc(100vh - 90px);
  }
}
