/* .ant-modal-wrap{
overflow-y: hidden !important; 
display: flex;
justify-content: center;
align-items: center; 
} */

.radio_check {
  width: 100%;
  display: flex;
  justify-content: center !important;
  margin-top: 16px;
}

.consent-content p {
  font-size: 12px;
}

.consent-content h1 {
  font-size: 24px;
}

.consent-content h2 {
  font-size: 20px;
}

.consent-content h3 {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    margin-right: 0;
    margin-left: 40%;
  }
  .ant-drawer-body {
    padding: 0;
    /* padding-block: 20px; */
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .steps-content .personal {
    padding-left: 50px;
  }
  .steps-action {
    width: fit-content;
    gap: 10px;
  }
  .steps-content .sectionScroll {
    height: 70vh;
    overflow-y: auto;
  }
  .payment-container-in-steps {
    padding-right: 30px;
  }
  .urgent-conformation-container {
    display: flex;
    flex-direction: column;
  }
  .urgent-conformation-text-container {
    display: flex;
    flex-direction: column;
  }
  .urgent-conformation-text-container .confirm-text {
    padding: 0;
  }
}
