@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#root {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}
.widthcontainer {
  width: 100%;
}
#payment-form {
  max-width: 550px;
  min-width: 300px;
  margin: 150px auto;
  background-color: #eb5757;
}
#card-container {
  margin-top: 45px;
  /* this height depends on the size of the container element */
  /* We transition from a single row to double row at 485px */
  /* Settting this min-height minimizes the impact of the card form loading */
  min-height: 90px;
}
#gift-card-container {
  margin-top: 45px;
  min-height: 90px;
}
.works-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #0d0d0d;
  margin-top: 100px;
  padding-bottom: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.active {
  color: #eb5757 !important;
}

.video-active {
  color: white !important;
}

.App-link {
  color: #61dafb;
}

/* .active{
  color: blue;
} */

@media screen and (min-width: 1400px) {
  .widthcontainer {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .widthcontainer {
    width: 1500px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 550px) {
  .widthcontainer {
    width: 100%;
  }
}
