.review-heading {
  font-weight: 700;
  font-size: 32px !important;
  line-height: 38px;
  font-family: Montserrat;
}
.reviewBg {
  background-position: left;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/Images/reviewBg.png);
  width: 430px;
  height: 442px;
  padding-left: 50px;
}
.review-box {
  width: 880px;
  height: 342px;
  background: #ffffff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.04),
    0px 0px 46.8519px rgba(0, 0, 0, 0.0303704),
    0px 0px 25.4815px rgba(0, 0, 0, 0.0242963), 0px 0px 13px rgba(0, 0, 0, 0.02),
    0px 0px 6.51852px rgba(0, 0, 0, 0.0157037),
    0px 0px 3.14815px rgba(0, 0, 0, 0.00962963);
  border-radius: 10px;
  margin-top: 50px;
  padding: 60px;
}
.review-boxM {
  background: #ffffff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.04),
    0px 0px 46.8519px rgba(0, 0, 0, 0.0303704),
    0px 0px 25.4815px rgba(0, 0, 0, 0.0242963), 0px 0px 13px rgba(0, 0, 0, 0.02),
    0px 0px 6.51852px rgba(0, 0, 0, 0.0157037),
    0px 0px 3.14815px rgba(0, 0, 0, 0.00962963);
  border-radius: 10px;
  /* margin-top: 50px; */
  padding: 30px;
}
.review-text {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #0d0d0d;
  padding-right: 75px;
  letter-spacing: 0.2px;
  line-height: 24px;
}
.review-name h2 {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #0d0d0d;
}
.review-name p {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}
.review-imageBg {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/Images/halfBg.png);
  height: 222px;
}
.review-image {
  margin-left: 18px;
  margin-top: 10px;
  height: 192px;
}
.slider {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}
@media screen and (max-width: 600px) {
  .review-image {
    margin-left: 18px;
    margin-top: 10px;
    height: 192px;
  }
  .responsiveWorkHeading {
    padding-bottom: 50px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .review-box {
    width: 90%;
  }
  .row-for-tabs {
    width: 93% !important;
  }
}
