.radio_check{
    width: 100%;
    display: flex;
    justify-content: center !important;
    margin-top: 16px;
}

.overFlowResponsive {
    overflow: hidden !important;

}