.assitance-heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  font-family: Montserrat;
}
.borderr-line {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  /* height: 2px solid #e0e0e0; */
}
.qestionDiv {
  align-items: center;
  padding: 18px 30px;
  font: 18px poppinsMd;
  color: #000;
  cursor: pointer;
  width: 100%;
  /* border: 1px solid #e0e0e0; */
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  /* border-left: 1px solid #e0e0e0; */

  user-select: none;
}
.qestionDiv .dot {
  display: flex;
  align-items: center;
}
.qestionDiv span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #202020;
  margin-left: 20px;
}
.text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  /* padding-left: 120px; */
}
.assistance-box {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  /* padding: 20px; */
}
.border-ab {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 10px;
}

.assis-title {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
}
.Need_ACO {
  width: 1000px !important;
}
@media (min-width: 768px) and (max-width: 992px) {
  .Need_ACO {
    width: 100% !important;
  }
}

@media screen and (max-width: 700px) {
  .Need_ACO {
    width: 340px !important;
  }
  @media screen and (max-width: 600px) {
    .responsiveWorkHeading {
      padding-bottom: 50px !important;
    }
  }
}
