@import "../../style/variables";
@import "../../style/mixins";

video-player-container {
  width: 100%;
  height: 100%;
}

video-local-player-container {
  height: 100%;
  width: 100%;
}

video-player {
  width: 100%;
  height: 100%;
  // aspect-ratio: 16/9;
}

.main-container-video-chat {
  min-height: 100vh;
  padding: 20px;

  @include phoneOnly {
    padding: 10px;
  }

  @include flex(column, flex-start, center);

  .video-chat-container-header {
    width: 100%;

    @include flex(row-reverse, space-between, center);
    padding: 15px;

    @include phoneOnly {
      flex-direction: row;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .video-chat-header-logo {
      cursor: pointer;

      @include phoneOnly {
        width: 80px;
        height: 40px;
      }
    }
  }
  .header-spacer {
    @include phoneOnly {
      height: 70px;
    }
  }

  .video-chat-section-container-main {
    width: 100%;
    height: calc(100vh - 100px);
    @include flex(row, space-between, center);

    .video-chat-inner-contianer {
      width: 74%;
      height: 100%;

      @include phoneOnly {
        width: 100%;
      }

      .videoDiv-main {
        width: 98%;
        height: 98%;
        background-color: black;
        border-radius: 20px;
        overflow: hidden;
        @include flex(row, center, center);
        position: relative;

        .title {
          color: white;
        }

        .remote-video {
          width: 100%;
          height: calc(100vh - 100px);
          border-radius: 20px;
          object-fit: cover;
        }
        video-player-container {
          width: 100%;
          object-fit: cover;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 15px;
        }

        .local-user-video {
          width: 180px;
          height: 100px;
          position: absolute;
          right: 35px;
          bottom: 30px;

          video-player {
            width: 100%;
            height: 100%;
            aspect-ratio: 4/3;
          }
          .local-video {
            width: 100%;
            height: 100%;
            background-color: grey;
            object-fit: cover;
            border-radius: 15px;
            overflow: hidden;
          }

          @include phoneOnly {
            position: fixed;
            bottom: 90px;
            width: 100px;
          }

          .local-user-off-cam {
            width: 100%;
            height: 100%;
            @include flex(column, center, center, 5px);
            background-color: black;
            border-radius: 15px;
            color: white;
            background-color: rgba(189, 189, 189, 0.5);

            i {
              font-size: 30px;
            }

            p {
              text-align: center;
              color: white;
              max-width: 80%;
            }
          }

          video {
            border-radius: 20px;
            box-shadow: 0px 0px 10px 0px opacityColor($primaryColor, 0.3);
          }
        }

        .chat-control-button {
          height: 40px;
          width: 40px;
          border-radius: 10px;
          background-color: $primaryColor;
          color: white;
          font-size: 17px;
        }

        .video-control-desktop {
          position: absolute;
          top: 15px;
          right: 15px;
        }

        .video-chat-controls {
          @include flex(row, flex-start, center, 10px);
          position: absolute;
          top: 15px;
          right: 15px;

          @include phoneOnly {
            position: fixed;
            width: calc(100% - 30px);
            background-color: $primaryBorderColor;
            padding: 15px 10px;
            border-radius: 20px;
            justify-content: space-around;
            top: auto;
            bottom: 12px;
          }

          .video-control-not-mobile {
            @include phoneOnly {
              display: none;
            }
          }

          .custom-button-video-call {
            @include tabletPortraitUp {
              display: none;
            }
          }
        }

        .video-active {
          background-color: rgb(243, 92, 92);
        }

        .no-other-user {
          p {
            color: white;
          }
        }

        .video-chat-main-buttons {
          width: 100%;
          @include flex(row, center, center, 20px);
          position: absolute;
          bottom: 15px;

          @include phoneOnly {
            display: none;
          }
        }

        .video-chat-button-dropdown-container-for-mobile {
          display: none;

          @include phoneOnly {
            display: block;
          }
        }

        .mobile-long-buttons-on-top {
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }
    }
  }

  .more-actions-button-in-video-call {
    font-size: 30px !important;
  }

  .report-button {
    background-color: #eba857;

    &:hover {
      background-color: opacityColor(#eba857, 0.7);
    }
  }

  .end-consultation {
    background-color: $errorColor;

    &:hover {
      background-color: opacityColor($errorColor, 0.7);
    }
  }

  .notify-receptionist {
    background-color: $primaryColor;

    &:hover {
      background-color: opacityColor($primaryColor, 0.7);
    }
  }

  .mobile-end-consultation {
    display: none;

    @include phoneOnly {
      display: block;
      width: 50px;
      padding: 0;
      height: 40px;
    }
  }

  .video-call-ended-text-in-box {
    width: 50%;
    text-align: center;
    color: white;
    align-self: center;
    margin: auto;

    @include phoneOnly {
      width: 80%;
    }

    p,
    .h4 {
      color: white;
    }

    .h4 {
      padding-bottom: 10px;
    }
  }
}

.custom-button-video-call {
  font-weight: 500;
  font-size: 16px;
  height: 45px;
  border-radius: 10px;
  padding-inline: 20px;
  color: #fff;
  background-color: #002fa7;
}
