.review-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #0d0d0d;
  margin-top: 55px;
  padding-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .review-heading {
    font-size: 18px;
  }
}
