@import "../../style/mixins";
@import "../../style/variables";

.prescriptions-main-container {
  padding: 20px;
  padding-left: 25px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include phoneOnly {
    padding: 0px;
  }

  .single-prescription-details-container {
    width: 100%;
    border-radius: 20px;
    background-color: rgb(236, 236, 236);
    padding: 20px;
    @include flex(column, flex-start, flex-start, 10px);
    @include phoneOnly {
      padding: 10px;
    }
    .prescription-status-checkbox-container {
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }
      label {
        position: relative;
        cursor: pointer;

        color: $primaryColor;

        &::before {
          content: "";
          -webkit-appearance: none;
          background-color: transparent;
          border: 4px solid $primaryColor;
          border-radius: 4px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
        }
      }
      input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 11.5px;
        width: 6px;
        height: 14px;
        border: 3px solid $primaryColor;
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
      }
    }
    .single-prescription-header {
      width: 100%;
      @include flex(row, space-between, center, 10px);

      h5 {
        font-weight: 600;
      }
      .single-prescription-header-div {
        @include flex(row, flex-end, center, 10px);

        @include phoneOnly {
          h5 {
            font-size: 12px;
          }
        }

        .status-prescription {
          padding: 3px 13px;
          margin-left: auto;
          width: fit-content;
          border-radius: 15px;
          background-color: $primaryColor;
          color: white;
          font-size: 14px;
          font-weight: 600;
          border: none;
          outline: none;
        }
        .yellow {
          background-color: $pendingColor;
          color: black;
        }
        .green-status {
          background-color: $successColor;
          color: white;
        }
      }
    }
    .single-prescription-all-medicines-container {
      width: 100%;
      @include flex(column, center, flex-start, 10px);
      .single-medicine-container {
        p {
          font-size: 12px;
        }
        .medicine-counter {
          font-size: 12px;
          color: $primaryColor;
          font-family: $secondaryFont;
        }
        .medicine-name-container {
          @include flex(row, flex-start, center, 10px);
          p {
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
    }
    .send-prescription-button {
      padding: 10px 13px;
      margin-left: auto;
      width: fit-content;
      border-radius: 10px;
      background-color: $primaryColor;
      color: white;
      font-size: 14px;
      font-weight: 600;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .single-prescription-side-by-side {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;
    }
    .show-only-on-mobile {
      display: none;
      @media (max-width: 768px) {
        display: flex;
      }
    }
    .show-only-on-desktop {
      display: block;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
