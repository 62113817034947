.hero-sec {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../../../assets/Images/background-main.png);
  /* width: 100%; */
  /* height: 100%; */
  position: absolute;
  top: 0;
  /* width: auto; */
}
/* .hero-sec-row{
  width: 100% !important;
} */
.category-images {
  margin-right: 12px;
}
div[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.after-hero-sec {
  width: 100%;
  /* margin: 0px auto;
  max-width: 1256px; */
}
.nav.toolbar-navigation {
  width: 100%;
  margin: 0px auto;
  max-width: 1268px;
}
.hero-heading h2 {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-size: 40px;
  line-height: 49px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* letter-spacing: 2px; */
  margin-bottom: 20px !important;
  padding-left: 20px;
  color: #0d0d0d !important;
  font-weight: 600;
  line-height: 1.5;
}

.hero-heading p {
  font-family: "Adamina" !important;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #686868 !important;
  padding-left: 60px;
}

.category-box {
  /* width: 350px; */

  min-height: 130px;
  max-height: auto;
  background: #ffffff;
  box-shadow: 0px 17px 80px rgba(0, 0, 0, 0.03),
    0px 11.0185px 46.8519px rgba(0, 0, 0, 0.0227778),
    0px 6.54815px 25.4815px rgba(0, 0, 0, 0.0182222),
    0px 3.4px 13px rgba(0, 0, 0, 0.015),
    0px 1.38519px 6.51852px rgba(0, 0, 0, 0.0117778),
    0px 0.314815px 3.14815px rgba(0, 0, 0, 0.00722222);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.category-box :before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #eb5757;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}
.category-box1 :before {
  background: #eb5757;
}
.category-box2 :before {
  background: #0d0d0d;
}
.category-box3 {
  cursor: not-allowed;
}
.category-box3 :before {
  background: #012fa7;
}

.category-box :hover:before {
  transform: scale(30);
}

.category-box h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #0d0d0d;
}
.card-box-american {
  width: 100%;
  max-width: 1170px;
  margin: 5px !important;
}

.category-box h5 {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #828282;
  margin-top: 6px;
  padding-right: 5px;
  /* word-spacing: 2px; */
  letter-spacing: 0.2px;
}

.category-box:hover h5 {
  color: white;
}

.category-box:hover h1 {
  color: white;
}

.category-content {
  padding-right: 12px;
  /* padding-top: 34px; */
  /* padding-right: 30px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 122px);
}
.category-content .category-card-right-content .category-card-heading {
  font-size: 17px;
}
/* .category-card-right-content {
  display: flex;
  align-items: center;
} */
.hero-ticks-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: fit-content;
  margin-inline: auto;
  padding-top: 20px;
}
.hero-ticks-section .hero-ticks-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
.hero-ticks-section .hero-ticks-container p {
  padding: 0px;
  font-size: 16px;
}
.hero-ticks-section .hero-ticks-container svg {
  min-width: 27px;
}
.category-button {
  width: 40px;
  height: 35px;
  border-radius: 5px;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  /* line-height: 15px; */
  color: #ffffff;
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-button svg {
  display: block;
}
.blue-color {
  background-color: #012fa7;
}
.black-color {
  background-color: #0d0d0d;
}
.red-color {
  background-color: #eb5757;
}
.tabs-image {
  display: none;
}
.other-images {
  display: block;
  height: 148px;
}
.info-at-hero-section-container {
  width: fit-content;
  margin-inline: auto;
  padding-top: 20px;
}
.info-at-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 13px;
  border-radius: 10px;
  background-color: #0130a722;
  max-width: 560px;
  width: fit-content;
  margin-left: 60px;
}

.info-at-hero-section-container .info-at-hero-section span {
  color: #012fa7;
  font-size: 14px;
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  .hero-sec {
    background-image: none;
  }
}
@media screen and (max-width: 767px) {
  .hero-heading h2 {
    font-weight: 700;
    font-size: 35px;
  }
  .hero-sec {
    background-image: none;
  }
  .after-hero-sec {
    /* padding-bottom: 42rem; */
  }
  .imgToShowInBgMobile {
    display: none !important;
  }
  .info-at-hero-section {
    margin-left: 0px;
  }
  .info-text-in-hero {
    text-align: left !important;
  }
}
@media screen and (max-width: 600px) {
  .hero-heading h2 {
    font-weight: 700;
    font-size: 28px;
    padding-left: 0px;
    line-height: 38px;
  }
  /* .toolbar .toolbar-navigation {
     
  } */
  .hero-heading p {
    padding-left: 20px;
  }
  /* .category-box {
    /* width: 340px; */

  .category-box h5 {
    padding-right: 0px;
  }
}
@media screen and (max-width: 400px) {
  .hero-heading h2 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
  }
  .category-content {
    padding-top: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .category-box {
    flex-direction: column;
    padding-bottom: 10px;
    text-align: center;
  }

  .category-box .category-images {
    width: 100%;
    margin-right: 0;
  }
  .category-box .category-images img {
    width: 100% !important;
    margin-right: 0;
    height: 150px;
    object-fit: cover;
  }
  .tabs-image {
    display: block;
  }
  .other-images {
    display: none;
  }
  .category-content {
    width: 100%;
    padding-left: 12px;
    flex-direction: column;
    margin-top: 10px;
  }
  .category-content a {
    width: 100%;
  }
  .category-button {
    width: 100%;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  .category-button {
    width: 140px;
  }
  .category-content {
    padding-top: 0;
    margin-block: auto;
  }
  .category-content {
    width: 100%;
    padding-left: 12px;
  }
}
