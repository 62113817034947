.video-content h1 {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0d0d0d;
  margin-top: 30px;
}
.video-two {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #828282;
  padding-right: 265px !important;
  margin-top: 10px;
}
div[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.video-content .video-para {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
  padding-right: 80px !important;
  margin-top: 10px;
  line-height: 21px;
  letter-spacing: 0.3px;
}
.video-para-two {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
  padding-right: 80px !important;
  margin-top: 10px;
  line-height: 21px;
  letter-spacing: 0.3px;
}
.video-content button {
  color: #002fa7;
  background-color: #e5eaf6;
  height: 45px;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.urgent h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0d0d0d;
  margin-top: 30px;
}
.urgent p {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
  margin-top: 10px;
  padding-right: 95px;
  line-height: 21px;
  letter-spacing: 0.3px;
}
.urgent button {
  color: #002fa7;
  background-color: #e5eaf6;
  height: 45px;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.urgent .disease {
  width: auto;
  height: 45px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #828282;
  /* padding-left: 18px; */
  margin-right: 10px;
}
.disease:hover {
  color: #fff;
  background-color: #002fa7;
}
.booking-button:hover {
  color: #fff;
  background-color: #002fa7;
  fill: #fff;
}
.urgentCareCard {
  display: flex;
  justify-content: end;
}
.urgentCareCareRes {
  display: none;
  margin: 0px auto !important;
}
/* @media screen and (min-width: 991px) {
  .works-heading{
    margin-top: 8%;
  }

} */
/* @media screen and (max-width: 767px) {
  .works-heading{
    padding-top: 33rem;
  }
} */
@media screen and (max-width: 600px) {
  .video-content .video-para {
    padding-right: 0px !important;
  }
  .urgent .disease {
    font-size: 10px;
    padding-left: 14px;
  }
  .urgent p {
    padding-right: 0px;
  }
  .video-para-two {
    padding-right: 0px !important;
  }
  .video-two {
    padding-right: 0px !important;
  }
  .booking-button {
    font-weight: 400 !important;
  }
  .urgentCareCard {
    display: none;
  }
  .urgentCareCareRes {
    display: flex !important;
    justify-content: center !important;
  }
}
@media screen and (max-width: 420px) {
  /* .padd {
    padding-top: 65rem;
  } */
}

@media (min-width: 768px) and (max-width: 992px) {
  #howItWorks {
    padding-top: 55rem;
    text-align: center;
  }
  #howItWorks p {
    display: block;
    padding-inline: 0 !important;
    margin-inline: auto;
  }
  .video-content {
    margin-left: 0 !important;
  }
  #howItWorks .booking-button {
    width: fit-content !important;
    margin-inline: auto;
  }
}
