.ant-drawer-content-wrapper {
  width: 72% !important;
}
@media screen and (max-width: 500px) {
  .ant-drawer-content-wrapper {
    width: 90% !important;
  }
}

.details-drawer-consultation-in-video-call {
}
@media screen and (max-width: 768px) {
  .details-drawer-consultation-in-video-call {
    width: 40px;
    padding: 0;
  }
  .details-drawer-consultation-in-video-call span {
    display: none;
  }
}

.details-drawer-consultation-in-video-call svg path {
  fill: white;
}
