.VideoBg {
  background-color: #edf2ff;
  background-image: url("../../../assets/Images/background.png");
  background-repeat: no-repeat;
  /* padding-bottom: 80px; */
  /* height: 85vh; */
  background-position: 100%;
}
.Bg {
  background-color: #edf2ff;
  width: 100%;
  overflow: hidden;
}
.med_history_lable {
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  color: #0d0d0d;
  font-family: "Poppins";
  margin-left: 4px;
}
.personal h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: flex-end;
  color: #0d0d0d;
  margin-top: 30px;
}
.personal p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #828282;
  margin-top: 20px;
}
.addAllergyDropdown {
  width: 100%;
  align-items: center;

  padding-left: 10px;
}
.addAllergyDropdown input {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 60%;
  padding-left: 10px;
  outline: 0;
  height: 20px;
}
.addAllergyDropdown button {
  margin-left: 8px;
  color: rgb(0, 47, 167);
  font-weight: 900;
}
.personal h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: #bdbdbd;
}
.border-b {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  width: 212px;
}
.border-medical {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  width: 262px;
}
.border-pay {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  width: 292px;
}
.first-step {
  border-top: 4px solid #002fa7;
  width: 25%;
}
.second-step {
  border-top: 4px solid #002fa7;
  width: 50%;
}
.third-step {
  border-top: 4px solid #002fa7;
  width: 75%;
}
.fourth-step {
  border-top: 4px solid #002fa7;
  width: 100%;
}
.four-step {
  border-top: 4px solid #002fa7;
  width: 85%;
}
.five-step {
  border-top: 4px solid #002fa7;
  width: 100%;
}
.doctor-list-name {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
}
.doctor-list-special {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #bdbdbd;
}
.doctor-profile {
  border: 1px solid #edf2ff;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 20px;
  border-radius: 5px;
}
.navLine {
  border: 1px solid #e0e0e0;
}
.doctors-list {
  /* width: 540px; */
  height: 370px;
  background: #ffffff;
  border: 1px solid rgba(0, 47, 167, 0.15);
  border-radius: 10px;
  overflow-y: scroll;
}
.question {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0d0d0d;
}
.ant-select-focused {
  border: none;
  /* :focus {
    outline: 0;
  } */
}

.white-box {
  background: #ffffff;
  box-shadow: 0px 24px 80px rgba(0, 0, 0, 0.07),
    0px 15.5556px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 9.24444px 25.4815px rgba(0, 0, 0, 0.0425185),
    0px 4.8px 13px rgba(0, 0, 0, 0.035),
    0px 1.95556px 6.51852px rgba(0, 0, 0, 0.0274815),
    0px 0.444444px 3.14815px rgba(0, 0, 0, 0.0168519);
  border-radius: 10px;
  width: 687px;
  padding: 20px;
  color: #111;
}
.white-box h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #0d0d0d;
  margin-top: 20px;
}
.white-box p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #686868;
  margin-left: 10px;
}
.previous-btn {
  height: 40px;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(139, 134, 134) !important;
  background: transparent !important;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.detail-heading h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  padding-bottom: 5px;
}
.detail-line {
  border-top: 3px solid #e0e0e0;
}
.continue-btn {
  width: 140px;
  height: 40px;
  background: #002fa7 !important;
  border: 1px solid #002fa7 !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff !important;
}
.review-btn {
  width: 140px;
  height: 40px;
  background: #002fa7 !important;
  border: 1px solid #002fa7 !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff !important;
}
.input-check {
  height: 16px;
  width: 16px;
  margin-top: 2px;
}
.cancel-btn {
  height: 35px;
  background: #edf2ff !important;
  border: 1px solid #edf2ff !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ff0000 !important;
  padding-bottom: 10px;
}
.no-doctor {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #828282;
  text-align: center;
}
input[type="checkbox"] {
  accent-color: #002fa7;
  border-radius: 50%;
}
input[type="checkbox"]:hover {
  accent-color: #002fa7;
}
.terms p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #0d0d0d;
  margin-left: 10px;
}

.available-slots h5 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
}
.check-inbox {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
}

.price {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.price p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
}
.confirm-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 21px;
  color: #686868;
}
.skip-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #002fa7;
}
.add-btn {
  background: rgba(0, 47, 167, 0.05);
  font-family: "Poppins";
  font-size: 13px;
  color: #002fa7;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 8px;
}
.add-btn-disabled {
  background-color: #bfbfbf;
  color: #000;
}
.btn-confirm {
  width: 208px;
  height: 40px;
  background: #002fa7 !important;
  border: 1px solid #002fa7;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.btn-consultation {
  width: 208px;
  height: 40px;
  border: 1px solid #002fa7;
  color: #002fa7 !important;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.react-calendar {
  width: 400px !important;
  /* height: 300px !important; */
  padding: 10px !important;
  border-radius: 10px !important;
  border: none !important;
}
.react-calendar__navigation button {
  background: #002fa7 !important;
  color: #fff;
  /* border-radius: 10px !important; */
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em !important;
}
/* .react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  color: #0d0d0d;
} */
.schedule-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #0d0d0d;
  margin-top: 10px;
}
.time-slot-btn {
  width: 161px;
  height: 35px;
  background: rgba(0, 47, 167, 0.05);
  border: 1px solid rgba(0, 47, 167, 0.15);
  border-radius: 8px;
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #002fa7;
}
.model {
  margin-bottom: 20px;
}
.selected-time-slot {
  width: 150px;
  height: 35px;
  background: #002fa7 !important;
  border-radius: 8px;
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.border-confirm {
  border-bottom: 1px solid #e0e0e0;
}
.confirm-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #0d0d0d;
}
.confirm-text {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  color: #686868;
}
.confirm-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #002fa7;
}
.payment-total {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  color: #0d0d0d;
}
.payment-save {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #0d0d0d;
}
.confirm-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #0d0d0d;
}
.logoImg {
  margin-left: 0px;
}

.medical-history-in-booking-page {
  max-height: 400px;
  overflow: auto;

  background:
    /* Shadow Cover TOP */ linear-gradient(
        transparent 30%,
        rgba(255, 255, 255, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */
      linear-gradient(rgba(255, 255, 255, 0), transparent) center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

@media screen and (max-width: 600px) {
  .white-box {
    width: 95%;
  }
  .btn-confirm {
    width: 208px;
    height: 40px;
    background: #002fa7 !important;
    border: 1px solid #002fa7;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .previous-btn {
    width: 90px;
    height: 35px;
    margin-left: 20px;
  }
  .review-btn {
    width: 120px;
    height: 35px;
  }
  .continue-btn {
    width: auto;
    height: 35px;
  }
  .doctors-list {
    width: 100%;
    padding: 10px;
  }
  .time-slot-btn {
    width: 161px;
    height: 35px;
    background: rgba(0, 47, 167, 0.05);
    border: 1px solid rgba(0, 47, 167, 0.15);
    border-radius: 8px;
    font-family: "Adamina";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #002fa7;
  }
  .selected-time-slot {
    width: 161px;
    height: 35px;
    background: rgba(0, 47, 167, 0.05);
    border: 1px solid rgba(0, 47, 167, 0.15);
    border-radius: 8px;
    font-family: "Adamina";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }
  .confirm-title {
    font-size: 20px;
    line-height: 22px;
  }
}

@media screen and (max-width: 760px) {
  .logoImg {
    margin-left: 90px;
  }
}
