.toolbar-navigation {
  width: 100%;
  height: 70px;

  z-index: 899;
}
.toolbar {
  position: fixed;
  width: 100%;
  height: 70px;
  left: 0px;
  top: 0;
  z-index: 899;
  background-color: white;
}
.bgg-transparent {
  background-color: transparent !important;
}
.shadow {
  box-shadow: 0px 17px 10px rgb(0 0 0 / 1%),
    0px 11.0185px 26.8519px rgb(0 0 0 / 1%),
    0px 6.54815px 15.4815px rgb(0 0 0 / 1%), 0px 3.4px 13px rgb(0 0 0 / 1%),
    0px 1.38519px 6.51852px rgb(0 0 0 / 1%), 0px 0.314815px 1;
}
/* .toolbar .toolbar-navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 10px;
} */

.nav-items li {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.toolbar .toolbar-navigation-items ul {
  list-style: none;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  padding: 0 !important;
  height: 100%;
  align-items: center;
  margin: 0 !important;
}
.toolbar-logo h2 {
  font-family: "Mitr";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1d4069;
  cursor: pointer;
}

.toolbar .toolbar-navigation-items li {
  padding: 5px 15px;
}
.toolbar .toolbar-logo {
  margin-left: 10px;
  display: flex;
}
.logo-text {
  margin-left: 10px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #1d4069;
}

.toolbar .toolbar-logo a {
  text-decoration: none;
}
.toolbar .toolbar-navigation-items a {
  text-decoration: none;
  color: #828282;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.color-change {
  color: yellow !important;
}
.toolbar .toolbar-navigation-items a:hover,
.toolbar .toolbar-navigation-items a:active {
  opacity: 0.6;
  /* color: black !important; */
}
.nav-dropdown {
  background: #ffffff;
  box-shadow: 0px 0px 67px rgba(0, 0, 0, 0.03),
    0px 0px 39.2384px rgba(0, 0, 0, 0.0227778),
    0px 0px 21.3407px rgba(0, 0, 0, 0.0182222),
    0px 0px 10.8875px rgba(0, 0, 0, 0.015),
    0px 0px 5.45926px rgba(0, 0, 0, 0.0117778),
    0px 0px 2.63657px rgba(0, 0, 0, 0.00722222);
  border-radius: 15px;
  width: 181px;
  height: 165px;
  margin-top: 10px;
  margin-left: -120px;
  padding: 15px;
}
.nav-dropdown a {
  text-decoration: none !important;
  color: none;
  font-family: "Poppins", sans-serif !important;
}
.nav-dropdown h4 {
  cursor: pointer !important;
  font-family: "Poppins", sans-serif !important;
}

.spacer {
  flex-grow: 1;
}

.login-btn-nav {
  width: 110px;
  height: 45px;
  color: #0d0d0d !important;
  margin-right: 10px;
  text-decoration: none;
  font-weight: 700;
}

.nav-line {
  border-right: 3px solid #381050;
  opacity: 0.15;
  width: 15px;
  height: 45px;
  padding-left: 20px;
  /* padding-right: 50px; */
}
.register-btn-nav {
  width: 120px;
  height: 45px;
  color: #fff;
  border: 1px solid #002fa7;
  background: #002fa7 !important;
  border-radius: 10px;
}
a {
  text-decoration: none !important;
  /* color: #381050 !important; */
}
a p {
  padding-top: 15px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 760px) {
  .toolbar-navigation-items {
    display: none;
  }
}
@media (min-width: 761px) {
  .toggle-button {
    display: none;
  }
}
@media (max-width: 420px) {
  .mobile-toolbar-logo {
    margin-left: 15px !important;
  }
  .toolbar-logo {
    width: auto;
    height: auto;
  }
  .toolbar .toolbar-navigation {
    padding: 0px;
  }
  .toggle-button img {
    width: 25px;
    height: auto;
  }
  .nav-dropdown {
    margin-left: -125px;
  }
  .nav-line {
    display: none;
    width: 0;
    height: 0;
  }
}
@media (max-width: 350px) {
  .toolbar {
    height: auto;
  }
  .toolbar .toolbar-navigation {
    padding: 15px 10px;
  }
}
