.footer-text {
  font-family: "Adamina" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}
.selected {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  /* width: 76px; */
  padding-top: 0px !important;
  color: #eb5757 !important;
}

.footer-box {
  background: #ffffff;
  box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.05),
    0px 0px 38.0671px rgba(0, 0, 0, 0.037963),
    0px 0px 20.7037px rgba(0, 0, 0, 0.0303704),
    0px 0px 10.5625px rgba(0, 0, 0, 0.025),
    0px 0px 5.2963px rgba(0, 0, 0, 0.0196296),
    0px 0px 2.55787px rgba(0, 0, 0, 0.012037);
  border-radius: 10px 10px 0px 0px;
}
.x-line {
  width: 9px;
  height: 0px;
  border: 1px solid #828282;
  transform: rotate(90deg);
  margin-left: 5px;
  margin-right: 5px;
}
.y-line {
  width: 200px;
  /* margin-left: 120px;
  margin-right: 50px; */
  border: 1px solid #e0e0e0;
}
.footer-pages .pa {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  cursor: pointer;
  /* width: 76px; */
  padding-top: 0px !important;
}

/* .footer-copyright{
  padding-left: 150px;
} */

.footer-dot {
  width: 5px;
  height: 5px;
  background: #d9d9d9;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .contacts {
    /* padding-left: 35px; */
  }
  .footer-pages .pa {
    width: 100%;
  }
  .footer-text {
    font-family: "Adamina" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #828282;
  }
}
