.disclaimer-box {
  width: 100%;
  max-width: 600px;
  height: 345px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow-y: scroll;
}
.disclaimer-box p {
  font-family: "Adamina";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: justify;
  color: #686868;
}
.bold-text {
  font-family: "Montserrat" !important;
  font-style: bold;
  font-weight: 700 !important;
  font-size: 12px;
  color: #0d0d0d !important;
}
