 h1,h2,h3,div {
      font-size: 12px;
 }
 .faxNearest .ant-modal-content {
width: 1000px; 
}


/* .faxResponsive{
background-color: black;
} */